import payload_plugin_c5WSz_NgHrbGrf370s8vHB8MlcFnE7yE7ImVkGc2CeM from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.11.0_magicast@0.3.5_pinia@3.0.2_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_t86KlOp6FHLITsKf_tGTqxr4kPbFqRWbENV1LP3r4Og from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dGSE24pBXDLdy7yTJDQt7Z_yhGZBQMSzn3SjV0Nnd0A from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CgFqOpuD4k_sVYtclRsRzg3effNfvLJ6WPmbCZxbl74 from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_N6Qu3E4cdGV5X_3aDigP3H8OdRxv2kecst1iNZNG_fU from "/srv/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_oFneOdiTYtCLhPONMYcMmozSeEGWLJ0x1UfgZRPPGYE from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cxWvFx5ZRXQGZJCpna6CugkMWgPU_eSp4wzC0_HsmQI from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HjBUuNq0riHBQPDDrcIH_T_zWfdf6_aiWyxUrAp4JrA from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hBJ1vDHuoU5jruCCwLGhDWcpWz8WjDgw9OOcZZrkL3A from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qQ_E_ED7E5DgpyC2pIAWlAipn5L8pCoWTYKvs6go_y8 from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.11.0_magicast@0.3.5_pinia@3.0.2_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import switch_locale_path_ssr_5_4gdoRDu5khDSMpyDoVcMiacF4JuRfHEEbYFhcSmy8 from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_L7w1vdZta3_7TnjkPpFM0hF7_CFdr1S6U6S_oGjSiNM from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_01PaoGbIeJpsQwRd76picQwvI1QmsRpfKaber01QL98 from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_iR5DWC25VJ9u_xx1czB8CF9l803RWzntho4UartvaB0 from "/srv/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_721b3d023514a5e50b3452b65cc6f937/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_DpaUXUKNGzPQPM7Ta5idOPt5Af3WiiVJGKLlhlriW28 from "/srv/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import siteConfig_hdpRN23YX_wR8OD58Sg1G4tH6tcrwoKyK8Pl1S3BayU from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_IEKaISKfWVFaAXSrS7U4hSwjedxccEk73NEozmpAYwM from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_H40pwLYgOWe_U1YIHfUtfJ5NiMkHB7kj0KDF02BKOts from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_h9E8SEKGPlz0qQNRqV8_BJq1qPCEC8IJpd_j73cii_0 from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_BpsIZo4wnKwFna4DNv5DtsmvZodApmCaA6WILnkuApk from "/srv/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0 from "/srv/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_lPmTdaZMb5pW3A7ZrkXzWIhY4UUv3FFIkm5xXEhykvc from "/srv/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@9.0.1_magicast@0.3.5_webpack@5.98.0_esbuild@0.25.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import sentry_client__sO4l0i4mWMke7TkJdxdUva7IpBmYcSrouhZGOe_jCM from "/srv/app/node_modules/.pnpm/@sentry+nuxt@9.12.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1__0886dbc6e012741f85f7364b5ecc6b3d/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_wjhrcCL76ies_5saPwWOCD7XHMK0wr7fih8C3HPfjCo from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/sentry-client-config.mjs";
import dayjs_p2OKE00uxZOw__7bPvNteaMaTW1bYCED15GMqLobemA from "/srv/app/src/app/plugins/dayjs.ts";
import i18n_rgpBWqceGhUXVUoLOJ2oM3jvlmYalZprXt7Hyqw4ZVg from "/srv/app/src/app/plugins/i18n.ts";
import notification_client_v_6RuAjrzGU2j110j4QCAo5glu_LLfwrBOrzDQtV1n8 from "/srv/app/src/app/plugins/notification.client.ts";
import urql_t9p8JIAfp3hNTh1f_FERMYwuXgMlho9pnMHB3mefCnE from "/srv/app/src/app/plugins/urql.ts";
import ssg_detect_e9FVf5uBwf5fxhGeK8UelIxp0g7JSWUWV_gsHB6G0zo from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import pwa_client_gxe5qbnBP3R0mhDbdSYA0xlM4uIzqGQM60Ja_Behqgg from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_lightning_2f4ab5788443e8666131d84129784197/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_routeRules_E2zCUCP22UXdth4AHVGRhZ58u2RTq8Z3yHln6bdoOGM from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
export default [
  payload_plugin_c5WSz_NgHrbGrf370s8vHB8MlcFnE7yE7ImVkGc2CeM,
  revive_payload_client_t86KlOp6FHLITsKf_tGTqxr4kPbFqRWbENV1LP3r4Og,
  unhead_dGSE24pBXDLdy7yTJDQt7Z_yhGZBQMSzn3SjV0Nnd0A,
  router_CgFqOpuD4k_sVYtclRsRzg3effNfvLJ6WPmbCZxbl74,
  _0_siteConfig_N6Qu3E4cdGV5X_3aDigP3H8OdRxv2kecst1iNZNG_fU,
  payload_client_oFneOdiTYtCLhPONMYcMmozSeEGWLJ0x1UfgZRPPGYE,
  navigation_repaint_client_cxWvFx5ZRXQGZJCpna6CugkMWgPU_eSp4wzC0_HsmQI,
  check_outdated_build_client_HjBUuNq0riHBQPDDrcIH_T_zWfdf6_aiWyxUrAp4JrA,
  chunk_reload_client_hBJ1vDHuoU5jruCCwLGhDWcpWz8WjDgw9OOcZZrkL3A,
  plugin_vue3_qQ_E_ED7E5DgpyC2pIAWlAipn5L8pCoWTYKvs6go_y8,
  switch_locale_path_ssr_5_4gdoRDu5khDSMpyDoVcMiacF4JuRfHEEbYFhcSmy8,
  route_locale_detect_L7w1vdZta3_7TnjkPpFM0hF7_CFdr1S6U6S_oGjSiNM,
  i18n_01PaoGbIeJpsQwRd76picQwvI1QmsRpfKaber01QL98,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_iR5DWC25VJ9u_xx1czB8CF9l803RWzntho4UartvaB0,
  plugin_client_DpaUXUKNGzPQPM7Ta5idOPt5Af3WiiVJGKLlhlriW28,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  siteConfig_hdpRN23YX_wR8OD58Sg1G4tH6tcrwoKyK8Pl1S3BayU,
  inferSeoMetaPlugin_IEKaISKfWVFaAXSrS7U4hSwjedxccEk73NEozmpAYwM,
  titles_H40pwLYgOWe_U1YIHfUtfJ5NiMkHB7kj0KDF02BKOts,
  defaultsWaitI18n_h9E8SEKGPlz0qQNRqV8_BJq1qPCEC8IJpd_j73cii_0,
  i18n_BpsIZo4wnKwFna4DNv5DtsmvZodApmCaA6WILnkuApk,
  plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0,
  plugin_lPmTdaZMb5pW3A7ZrkXzWIhY4UUv3FFIkm5xXEhykvc,
  sentry_client__sO4l0i4mWMke7TkJdxdUva7IpBmYcSrouhZGOe_jCM,
  sentry_client_config_wjhrcCL76ies_5saPwWOCD7XHMK0wr7fih8C3HPfjCo,
  dayjs_p2OKE00uxZOw__7bPvNteaMaTW1bYCED15GMqLobemA,
  i18n_rgpBWqceGhUXVUoLOJ2oM3jvlmYalZprXt7Hyqw4ZVg,
  notification_client_v_6RuAjrzGU2j110j4QCAo5glu_LLfwrBOrzDQtV1n8,
  urql_t9p8JIAfp3hNTh1f_FERMYwuXgMlho9pnMHB3mefCnE,
  ssg_detect_e9FVf5uBwf5fxhGeK8UelIxp0g7JSWUWV_gsHB6G0zo,
  pwa_client_gxe5qbnBP3R0mhDbdSYA0xlM4uIzqGQM60Ja_Behqgg,
  _0_routeRules_E2zCUCP22UXdth4AHVGRhZ58u2RTq8Z3yHln6bdoOGM
]